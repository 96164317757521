import type { ClientResult } from "~/types/Clients";
import { Tool } from "~/enums/tool";

export default <ClientResult[]>[
	{
        client: 2,
		icon: 'airplane-duotone',
		sector: 'Insurance',
        solutions: ['Real-time asset tracking portal', 'Data analytics portal', 'No-code client risk portal', 'AI-driven risk management assistant'],
        results: ['Processing over 1GB flight data daily', 'Multiple new clients won due to this technology', 'Better data to inform underwriting decisions', 'Companies exit multiple increased by 8-10'],
		tools: [Tool.AWS, Tool.ELESTIO, Tool.MYSQL, Tool.NESTJS, Tool.VUEJS]
	},
	{
        client: 3,
		icon: 'monitor-duotone',
		sector: 'SaaS',
        solutions: ['No-code workflows', 'Low-code API Automation', 'No-code client portal', 'Business operations automation', 'CTO consultation services '],
        results: ['Client NPS score 8.7/10', 'Revenue growth of 240% in 2024', 'Reduction in entry-level staffing costs'],
		tools: [Tool.ATLASGO, Tool.ELESTIO, Tool.MYSQL, Tool.NESTJS, Tool.NUXTJS, Tool.VUEJS, Tool.N8N, Tool.DATAFORSEO, Tool.OPENAI, Tool.SPYFU]
	},
    {
        client: 6,
		icon: 'gift-duotone',
		sector: 'Ecommerce',
        solutions: ['No-code workflows', 'Full-code AI coaching app ', 'Full business operations automation', 'AI-powered customer service'],
        results: ['Customer service team reduction from 20 to 5', 'Average resolution time from 29.5h to 10.9h', 'Increase in cNPS from 7.1 to 7.9'],
		tools: [Tool.AWS, Tool.EVERFLOW, Tool.CREATIFY, Tool.BUFFER, Tool.NESTJS, Tool.NUXTJS, Tool.VUEJS, Tool.OPENAI, Tool.AWS, Tool.RECHARGE, Tool['GOOGLE-ANALYTICS'], Tool.SHIPBOB, Tool.SENDGRID, Tool.KLAVIYO, Tool.N8N, Tool.SHOPIFY]
	},
]
